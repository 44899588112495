exports.components = {
  "component---src-page-archive-tsx": () => import("./../../../src/page/archive.tsx" /* webpackChunkName: "component---src-page-archive-tsx" */),
  "component---src-page-default-tsx": () => import("./../../../src/page/default.tsx" /* webpackChunkName: "component---src-page-default-tsx" */),
  "component---src-page-post-tsx": () => import("./../../../src/page/post.tsx" /* webpackChunkName: "component---src-page-post-tsx" */),
  "component---src-page-social-tsx": () => import("./../../../src/page/social.tsx" /* webpackChunkName: "component---src-page-social-tsx" */),
  "component---src-page-twitter-archive-tsx": () => import("./../../../src/page/twitter-archive.tsx" /* webpackChunkName: "component---src-page-twitter-archive-tsx" */),
  "component---src-page-twitter-archive-year-month-tsx": () => import("./../../../src/page/twitter-archive-year-month.tsx" /* webpackChunkName: "component---src-page-twitter-archive-year-month-tsx" */),
  "component---src-page-twitter-archive-year-tsx": () => import("./../../../src/page/twitter-archive-year.tsx" /* webpackChunkName: "component---src-page-twitter-archive-year-tsx" */),
  "component---src-page-twitter-status-tsx": () => import("./../../../src/page/twitter-status.tsx" /* webpackChunkName: "component---src-page-twitter-status-tsx" */)
}

